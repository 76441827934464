<template>
  <section fluid>
    <v-breadcrumbs class="pl-0" large :items="breads"></v-breadcrumbs>
    <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-select
          label="Tipo de Oferta *"
          outlined
          disabled
          :items="ctlTipoOferta"
          item-value="id"
          item-text="nombre"
          v-model="idTipoOferta"
          @blur="$v.idTipoOferta.$touch()"
          :error-messages="tipoError"
          :readonly="!offerIsAllowed"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" sm="8" md="4">
        <v-file-input
          v-model="documentoOferta"
          outlined
          prepend-icon=""
          placeholder="Seleccione un archivo"
          label="Oferta *"
          @input="$v.documentoOferta.$touch()"
          @blur="$v.documentoOferta.$touch()"
          :error-messages="archivoError"
          prepend-inner-icon="mdi-paperclip"
          accept="application/pdf"
        />
        <vc-date-picker
          v-if="!haveRole('ROLE_PROVEEDOR') && $route.params.idParticipacionProveedor"
          v-model="date_var.value"
          mode="datetime"
          locale="es"
          is24hr
          :popover="config_calendar"
          :max-date="$moment().format('YYYY-MM-DD HH:mm')"
          :valid-hours="horas_disponibles"
          @dayclick="horasDisponibles()"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="py-1 border roundend focus:outline-none focus:border-blue-300"
              label="Fecha y hora de recepción *"
              prepend-inner-icon="mdi-calendar"
              readonly
              :value="getFechaFin"
              v-on="inputEvents"
              outlined
              clearable
            />
          </template>
        </vc-date-picker>
        <v-btn
          v-if="offer?.documento_oferta"
          block
          text
          class="justify-start"
          @click="downloadDocumentOffer"
        >
          <v-icon color="info">mdi-download</v-icon> Descargar oferta actual
        </v-btn>
      </v-col>
      <v-col v-if="!$route.params.idOferta" cols="12" sm="4" md="4">
        <v-btn block color="secondary" @click="sendOffer">
          <v-icon color="white">mdi-check</v-icon> Aplicar
        </v-btn>
      </v-col>
    </v-row>

    <h5 class="text-h5 mt-6">Seleccione los lotes a los que aplica</h5>
    <span v-if="!batchesAreSelected" class="red--text">
      Debe seleccionar por lo menos un lote para generar una oferta
    </span>

    <v-expansion-panels v-model="lotIdxToExpand" class="mt-6" tile>
      <v-expansion-panel
        id="exp-panel"
        v-for="(lote, index) in lotes"
        :key="index"
      >
        <!-- Header -->
        <v-expansion-panel-header>
          <div class="d-flex flex-row">
            <v-checkbox
              class="ma-0 chk-input"
              hide-details
              @click.native.stop
              @change="toggleLotList(lote?.id, this)"
              :input-value="lotsOnOffer.includes(lote?.id)"
            />
            <span class="mt-2">{{ lote.nombre }}</span>
          </div>
          <template v-slot:actions>
            <v-icon> $expand </v-icon>
          </template>
        </v-expansion-panel-header>

        <!-- Content -->
        <v-expansion-panel-content>
          <v-data-table
            class="mt-4"
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
            :items="datosLoteSeleccionado?.SubProcesoObs"
            no-data-text="No hay datos para mostrar"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-alert
      dense
      dark
      outlined
      type="info"
      class="mt-8"
      v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])"
    >
      Las modificaciones realizadas a las ofertas subidas por el proveedor serán notificadas al mismo.
    </v-alert>

    <div class="mt-8">
      <v-btn
        outlined
        color="secondary"
        @click="
          $router.replace({
            name: 'pac-procesos-etapas',
            params: { idProceso: $route.params.idProceso },
          })
        "
      >
        Regresar a proceso
      </v-btn>
      <v-btn
        dark
        @click="updateOffer()"
        color="secondary"
        class="ml-4"
        v-if="!offerIsAllowed"
      >
        Actualizar
      </v-btn>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "ProveedorOfertaView",
  data: () => ({
    documentoOferta: null,
    headers: [
      { text: "Obs", align: "start", value: "nombre_agrupacion" },
      { text: "Especificación", align: "start", value: "especificacion" },
      { text: "Cantidad", align: "center", value: "total_sub_proceso" },
      { text: "Presentación", align: "center", value: "Presentacion.nombre" },
    ],
    lotsOnOffer: [],
    snapshot: {},
    batchesAreSelected: false,
    offerIsAllowed: true,
    ctlTipoOferta: [
      {
        id: 1,
        nombre: "Oferta técnica",
      },
      {
        id: 2,
        nombre: "Oferta económica",
      },
    ],
    idTipoOferta: null,
    // Configuración del calendario
    config_calendar: {
      visibility: "click",
    },
    date_var: {
      value: null,
    },
    horas_disponibles: {},
  }),
  validations: {
    documentoOferta: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    idTipoOferta: {
      required,
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", [
      "procesoData",
      "selectedLot",
      "lotes",
      "datosLoteSeleccionado",
      "offer",
    ]),

    breads() {
      return [
        {
          text: "Mis procesos",
          disabled: false,
          href: "/proveedor-paac",
        },
        {
          text: this.procesoData.codigo_proceso,
          disabled: false,
          href: `/pac-procesos/etapas/${this.$route.params.idProceso}`,
        },
        {
          text: "Recepción de Ofertas",
          disabled: true,
        },
      ];
    },
    archivoError() {
      const errors = [];
      if (!this.$v.documentoOferta.$dirty) return errors;
      !this.$v.documentoOferta.required &&
        errors.push("El documento es requerido");
      !this.$v.documentoOferta.fileTypeValid &&
        errors.push("El documento debe ser un PDF");
      !this.$v.documentoOferta.fileNameValid &&
        errors.push(
          "El nombre del documento no debe contener caracteres especiales"
        );
      !this.$v.documentoOferta.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
    tipoError() {
      const errors = [];
      if (!this.$v.idTipoOferta.$dirty) return errors;
      !this.$v.idTipoOferta.required &&
        errors.push("El tipo de oferta es requerido");
      return errors;
    },

    lotIdxToExpand: {
      get() {
        return this.selectedLot;
      },
      async set(value) {
        if (value !== undefined) {
          await this.getLote({ id_lote: this.lotes[value].id });
          this.setSelectedLot(value);
        } else {
          this.setDatosLoteSeleccionado({
            nombre: null,
            descripcion: null,
          });
        }
      },
    },
    
    getFechaFin() {
      return this.date_var.value
        ? this.$moment(this.date_var.value, 'DD/MM/YYYY hh:mm A').format("DD-MM-YYYY hh:mm A")
        : "";
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", [
      "setInfoProceso",
      "setSelectedLot",
      "setDatosLoteSeleccionado",
      "setOffer",
    ]),
    ...mapActions("procesoCompraDoc", [
      "getProcesoCompra",
      "getLotes",
      "getLote",
      "storeProviderOffer",
      "getProviderOffer",
    ]),

    toggleLotList(idLot) {
      const index = Number(this.lotsOnOffer.indexOf(idLot));
      this.lotsOnOffer =
        index > -1
          ? [
              ...this.lotsOnOffer.slice(0, index),
              ...this.lotsOnOffer.slice(index + 1),
            ]
          : [...this.lotsOnOffer, idLot];
    },
    async sendOffer() {
      this.$v.$touch();
      this.batchesAreSelected = this.lotsOnOffer.length > 0 ? true : false;

      if (this.batchesAreSelected && !this.$v.$invalid) {
        const form = new FormData();
        form.append("lotes", JSON.stringify(this.lotsOnOffer));
        form.append("oferta", this.documentoOferta);
        form.append("id_tipo_oferta", this.idTipoOferta);
        if (this.$route.params.idParticipacionProveedor) {
          if ([null, ''].includes(this.date_var.value)) {
            this.temporalAlert({
              show: true,
              type: "warning",
              message: "La fecha de la oferta es requerida",
            })
            return false;
          }
          form.append(
            "id_participacion_proveedor",
            this.$route.params.idParticipacionProveedor
          );
          form.append(
            'fecha',
            this.$moment(this.date_var.value).format("YYYY-MM-DD HH:mm:ss")
          )
        }

        const response = await this.storeProviderOffer({
          id_proceso_compra: this.$route.params.idProceso,
          form,
        });
        if (response?.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La oferta ha sido registrada con éxito",
          });
          this.$router.replace({
            name: "pac-procesos-etapas",
            params: { idProceso: this.$route.params.idProceso },
          });
        }
      }
    },

    async updateOffer() {
      this.$v.$touch();
      this.batchesAreSelected = this.lotsOnOffer.length > 0 ? true : false;

      if (this.batchesAreSelected && !this.$v.$invalid) {

        const form = new FormData();
        form.append("lotes", JSON.stringify(this.lotsOnOffer));
        form.append("oferta", this.documentoOferta);
        if (this.$route.params.idParticipacionProveedor) {
          if ([null, ''].includes(this.date_var.value)) {
            this.temporalAlert({
              show: true,
              type: "warning",
              message: "La fecha de la oferta es requerida",
            })
            return false;
          }
          form.append(
            'fecha',
            this.$moment(this.date_var.value).format("YYYY-MM-DD HH:mm:ss")
          )
        }

        const response = await this.services.PacProcesos.editOfertaProveedor(
          this.$route.params.idOferta,
          form
        );
        if (response?.status === 200 || response?.status === 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La oferta ha sido actualizada con éxito",
          });
          this.$router.replace({
            name: "pac-procesos-etapas",
            params: { idProceso: this.$route.params.idProceso },
          });
        }

      }
    },

    async downloadDocumentOffer() {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.offer.documento_oferta,
          disk: "procesos",
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "oferta", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },

    horasDisponibles() {
      let hora_maxima = [];

      let fecha_minima = null;

      if (this.date_var.value) {
        fecha_minima = this.date_var.value;
      } else {
        fecha_minima = new Date();
      }

      if (
        fecha_minima.getDate() === new Date().getDate() &&
        fecha_minima.getMonth() === new Date().getMonth() &&
        fecha_minima.getFullYear() === new Date().getFullYear()
      ) {
        hora_maxima = new Date().getHours();
      } else {
        hora_maxima = 23;
      }

      this.horas_disponibles = { max: hora_maxima, min: 0 };
    },
  },
  async created() {
    this.idTipoOferta = this.$route.params.idTipoOferta;
    await this.getProcesoCompra({
      id_proceso_compra: this.$route.params.idProceso,
    });
    await this.getLotes({ id_proceso_compra: this.$route.params.idProceso });

    if (this.$route.params.idOferta) {
      await this.getProviderOffer({
        id_proceso_compra: this.$route.params.idProceso,
        id_oferta: this.$route.params.idOferta,
        id_participacion_proveedor: this.$route.params?.idParticipacionProveedor
      });

      this.lotsOnOffer = this.offer?.ParticipacionLotes?.map(
        (element) => element.id_lote
      );

      this.idTipoOferta = this.offer.id_tipo_oferta;

      this.batchesAreSelected = this.lotsOnOffer.length > 0 ? true : false;
      this.offerIsAllowed = false;
      this.date_var.value = this.$moment(this.offer.fecha_recepcion);
    } else {
      this.setOffer(null);
    }
  },
};
</script>
